import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { useSpring, animated, config } from 'react-spring';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaFacebook, FaTelegramPlane, FaYoutube } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { FaChartBar } from "react-icons/fa";
import PollyPage from './pollyPage';
import './App.css'

const GREEN_COLORS = {
  primary: '#2E8B57',    // Sea Green
  secondary: '#3CB371',  // Medium Sea Green
  accent: '#98FB98',     // Pale Green
  text: '#F0FFF0',       // Honeydew (light text color)
  background: '#006400'  // Dark Green
};

const generateGreenShades = () => {
  const baseColor = parseInt(GREEN_COLORS.primary.slice(1), 16);
  const shades = [];
  for (let i = 0; i < 20; i++) {
    const r = Math.max(0, Math.min(255, ((baseColor >> 16) & 0xFF) + (i - 10) * 5));
    const g = Math.max(0, Math.min(255, ((baseColor >> 8) & 0xFF) + (i - 10) * 10));
    const b = Math.max(0, Math.min(255, (baseColor & 0xFF) + (i - 10) * 5));
    const color = `#${((r << 16) | (g << 8) | b).toString(16).padStart(6, '0')}`;
    shades.push(color);
  }
  return shades;
};

const GREEN_SHADES = generateGreenShades();

const movePolly = keyframes`
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -400px 0;
  }
`;

const AppContainer = styled.div`
  position: relative;
  min-height: 100vh;
  width: 100vw;
  overflow: hidden;
`;

const BackgroundGif = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('./polly.gif');
  background-repeat: repeat;
  background-size: 100px 100px;
  animation: ${movePolly} 10s linear infinite;
  transition: background-color 0.5s ease;
  z-index: 1;
`;

const ContentContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
`;

const Card = styled(animated.div)`
  background: rgba(46, 139, 87, 0.2);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 2rem;
  box-shadow: 0 8px 32px 0 rgba(0, 100, 0, 0.37);
  border: 1px solid rgba(152, 251, 152, 0.18);
  width: 90%;
  max-width: 400px;
  text-align: center;
  color: ${GREEN_COLORS.text};
  font-family: skinnyFont;
`;

const Title = styled.h1`
  font-family: fatFont;
  font-size: 3rem;
  margin-bottom: 1rem;
  color: ${GREEN_COLORS.text};
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.3);
`;

const Subtitle = styled.p`
  font-size: 1.2rem;
  margin-bottom: 2rem;
  color: ${GREEN_COLORS.secondary};
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  font-family: skinnyFont;
`;

const ContractAddressInput = styled.input`
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  background: rgba(152, 251, 152, 0.2);
  border: none;
  border-radius: 5px;
  font-size: 0.9rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: skinnyFont;

  &::placeholder {
    color: rgba(240, 255, 240, 0.7);
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1rem;
`;

const StyledButton = styled(animated.button)`
  background-color: ${GREEN_COLORS.accent};
  color: ${GREEN_COLORS.background};
  border: none;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1),
              inset 0 -2px 5px rgba(0, 0, 0, 0.1),
              inset 0 2px 5px rgba(255, 255, 255, 0.5);
  flex: 1;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15),
                inset 0 -2px 5px rgba(0, 0, 0, 0.2),
                inset 0 2px 5px rgba(255, 255, 255, 0.7);
  }
`;

const SocialButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
`;

const SocialButton = styled.a`
  background-color: ${GREEN_COLORS.primary};
  color: ${GREEN_COLORS.text};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1),
              inset 0 -1px 3px rgba(0, 0, 0, 0.1),
              inset 0 1px 3px rgba(255, 255, 255, 0.5);

  &:hover {
    background-color: ${GREEN_COLORS.accent};
    color: ${GREEN_COLORS.background};
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15),
                inset 0 -1px 3px rgba(0, 0, 0, 0.2),
                inset 0 1px 3px rgba(255, 255, 255, 0.7);
  }
`;

const CopyPopup = styled(animated.div)`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${GREEN_COLORS.secondary};
  color: ${GREEN_COLORS.text};
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
  z-index: 10;
`;

function HomeComponent() {
  const [showCopyPopup, setShowCopyPopup] = useState(false);
  const [currentShadeIndex, setCurrentShadeIndex] = useState(0);
  const contractAddress = '4uNJh1SwJ5VyZXvRTBiXKRQ5CsipuSVQ68P3xVaSpump';

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentShadeIndex((prevIndex) => (prevIndex + 1) % GREEN_SHADES.length);
    }, 500);

    return () => clearInterval(interval);
  }, []);

  const cardProps = useSpring({
    from: { opacity: 0, transform: 'scale(0.8)' },
    to: { opacity: 1, transform: 'scale(1)' },
    config: config.wobbly,
  });

  const buttonProps = useSpring({
    from: { transform: 'scale(1)' },
    to: async (next) => {
      while (true) {
        await next({ transform: 'scale(1.05)' });
        await next({ transform: 'scale(1)' });
      }
    },
    config: { duration: 1000 },
  });

  const popupProps = useSpring({
    opacity: showCopyPopup ? 1 : 0,
    transform: showCopyPopup ? 'translate(-50%, 0)' : 'translate(-50%, 20px)',
    config: { tension: 300, friction: 10 },
  });

  const handleCopy = () => {
    setShowCopyPopup(true);
    setTimeout(() => setShowCopyPopup(false), 2000);
  };

  const handleBuyClick = () => {
    window.open('https://pump.fun/4uNJh1SwJ5VyZXvRTBiXKRQ5CsipuSVQ68P3xVaSpump', '_blank', 'noopener,noreferrer');
  };

  const navigate = useNavigate();

  const handleWhoIsClick = () => {
    navigate('/polly');
  };

  const getContrastColor = (hexColor) => {
    const r = parseInt(hexColor.slice(1, 3), 16);
    const g = parseInt(hexColor.slice(3, 5), 16);
    const b = parseInt(hexColor.slice(5, 7), 16);
    const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return (yiq >= 128) ? GREEN_COLORS.background : GREEN_COLORS.text;
  };

  const currentShade = GREEN_SHADES[currentShadeIndex];
  const contrastColor = getContrastColor(currentShade);

  return (
    <AppContainer>
      <BackgroundGif style={{ backgroundColor: currentShade }} />
      <ContentContainer>
        <Card style={cardProps}>
          <Title
            style={{
              textShadow: `3px 3px 6px ${contrastColor}80`,
              WebkitTextStroke: `1px ${GREEN_COLORS.accent}`,
              color: GREEN_COLORS.text
            }}
          >
            POLLY
          </Title>
          <Subtitle style={{ textShadow: `2px 2px 4px ${contrastColor}80`, color: GREEN_COLORS.text }}>The Parrot</Subtitle>
          <CopyToClipboard text={contractAddress} onCopy={handleCopy}>
            <ContractAddressInput
              value={contractAddress}
              readOnly
              placeholder="Click to copy contract address"
              style={{
                color: GREEN_COLORS.text,
                textShadow: `1px 1px 2px ${currentShade}`,
                border: `1px solid ${GREEN_COLORS.text}`,
                backgroundColor: `${GREEN_COLORS.background}80`
              }}
            />
          </CopyToClipboard>
          <ButtonContainer>
            <StyledButton
              onClick={handleBuyClick}
              style={{
                ...buttonProps,
                backgroundColor: GREEN_COLORS.accent,
                color: GREEN_COLORS.background,
                textShadow: `1px 1px 2px ${GREEN_COLORS.text}`,
                border: `1px solid ${GREEN_COLORS.background}`
              }}
            >
              Buy Polly
            </StyledButton>
            <StyledButton
              onClick={handleWhoIsClick}
              style={{
                ...buttonProps,
                backgroundColor: GREEN_COLORS.accent,
                color: GREEN_COLORS.background,
                textShadow: `1px 1px 2px ${GREEN_COLORS.text}`,
                border: `1px solid ${GREEN_COLORS.background}`
              }}
            >
              Who is Polly
            </StyledButton>
          </ButtonContainer>
          <SocialButtons>
            <SocialButton href="https://t.me/solanapolly" target="_blank" rel="noopener noreferrer" style={{ backgroundColor: GREEN_COLORS.primary, color: GREEN_COLORS.text }}>
              <FaTelegramPlane />
            </SocialButton>
            <SocialButton href="https://x.com/solanapolly" target="_blank" rel="noopener noreferrer" style={{ backgroundColor: GREEN_COLORS.primary, color: GREEN_COLORS.text }}>
              <FaXTwitter />
            </SocialButton>
            <SocialButton href="https://www.youtube.com/watch?v=sNP_NjPVKPs" target="_blank" rel="noopener noreferrer" style={{ backgroundColor: GREEN_COLORS.primary, color: GREEN_COLORS.text }}>
              <FaYoutube />
            </SocialButton>
            <SocialButton href="https://www.facebook.com/thepetcollective/videos/adorable-parrot-plays-peek-a-boo-with-themselves/761438755658536/" target="_blank" rel="noopener noreferrer" style={{ backgroundColor: GREEN_COLORS.primary, color: GREEN_COLORS.text }}>
              <FaFacebook />
            </SocialButton>
            <SocialButton href="https://pump.fun/4uNJh1SwJ5VyZXvRTBiXKRQ5CsipuSVQ68P3xVaSpump" target="_blank" rel="noopener noreferrer" style={{ backgroundColor: GREEN_COLORS.primary, color: GREEN_COLORS.text }}>
              <FaChartBar />
            </SocialButton>
          </SocialButtons>
        </Card>
      </ContentContainer>
      <CopyPopup style={popupProps}>Copied CA to Clipboard</CopyPopup>
    </AppContainer>
  );
}


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomeComponent />} />
        <Route path="/polly" element={<PollyPage />} />
      </Routes>
    </Router>
  );
}

export default App;