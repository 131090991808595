import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const DARK_THEME_COLORS = {
  primary: '#1b1b1b',    // Dark Gray
  secondary: '#3a3a3a',  // Mid Dark Gray
  accent: '#32CD32',     // Lime Green
  text: '#E6E6E6',       // Light Gray for text
  background: '#101010'  // Deep Dark Gray
};

const PageContainer = styled.div`
  background-color: ${DARK_THEME_COLORS.background};
  min-height: 100vh;
  padding: 2rem;
  color: ${DARK_THEME_COLORS.text};
`;

const Header = styled.h1`
  color: ${DARK_THEME_COLORS.accent};
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2.5rem;
`;

const Card = styled.div`
  background-color: ${DARK_THEME_COLORS.primary};
  border-radius: 15px;
  padding: 2rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.6);
  text-align: center;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.05);
  }
`;

const CardTitle = styled.h2`
  color: ${DARK_THEME_COLORS.accent};
  margin-bottom: 1rem;
`;

const CardContent = styled.p`
  color: ${DARK_THEME_COLORS.text};
`;

const StyledSlider = styled(Slider)`
  .slick-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  }
`;

const BackButton = styled(Link)`
  display: inline-block;
  background-color: ${DARK_THEME_COLORS.accent};
  color: ${DARK_THEME_COLORS.background};
  padding: 0.5rem 1.5rem;
  border-radius: 8px;
  text-decoration: none;
  font-weight: bold;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${DARK_THEME_COLORS.secondary};
  }
`;

const VideoContainer = styled.div`
  margin: 3rem auto;
  width: 100%;
  max-width: 300px; /* Set maximum width */
  height: 400px; /* Set fixed height */
  position: relative;
  border: 2px solid ${DARK_THEME_COLORS.accent};
  border-radius: 10px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.7);
`;


const VideoEmbed = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 10px;
`;

const parakeetFacts = [
  { title: "High Intelligence", content: "Rose-ringed parakeets are known for their intelligence, capable of solving complex puzzles and learning various tricks." },
  { title: "Social and Communicative", content: "These parakeets mimic human speech and form strong social bonds, displaying various emotions like excitement and curiosity." },
  { title: "Distinct Personalities", content: "Each bird can have a unique personality—some are playful and outgoing, while others are more introverted." },
  { title: "Monogamous Pairing", content: "They form lifelong pairs, working together to raise young, resembling human long-term relationships." },
  { title: "Adaptability", content: "Rose-ringed parakeets can thrive in both urban and rural environments, showing human-like adaptability." },
  { title: "Mimicking Speech", content: "They can mimic human speech patterns and repeat words they hear, demonstrating social learning." },
  { title: "Territorial and Possessive", content: "These birds can be quite territorial, displaying jealousy and possessiveness similar to human emotions." },
  { title: "Mischievous and Playful", content: "They enjoy play and exploration, sometimes getting into trouble out of sheer curiosity." }
];

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
};

const PollyPage = () => {
  return (
    <PageContainer>
      <Header>About the Rose-Ringed Parakeet Polly</Header>
      {/* Carousel of Parakeet Facts */}
      <StyledSlider {...sliderSettings}>
        {parakeetFacts.map((fact, index) => (
          <Card key={index}>
            <CardTitle>{fact.title}</CardTitle>
            <CardContent>{fact.content}</CardContent>
          </Card>
        ))}
      </StyledSlider>

      {/* Embedded YouTube Short */}
      <VideoContainer>
        <VideoEmbed
          src="https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2Fthepetcollective%2Fvideos%2F761438755658536%2F"
          title="YouTube Short: Rose-ringed Parakeet"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </VideoContainer>

      <BackButton to="/">Back to Home</BackButton>
    </PageContainer>
  );
};

export default PollyPage;
